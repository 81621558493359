.video-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px;
  }
  
  .channel-container {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
  
  .video-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .video-item {
    width: calc(33.33% - 10px);
    margin-bottom: 50px;
  }
  
  .video-item h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .video-item iframe {
    width: 100%;
    height: 100%;
  }
  