.contents1{
    display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.icons1 {
    color: #f3ec1a;
    font-size: 50px !important;
}
.contactCard1 {
    text-align: left;
    background-color: #633e98 !important;
    padding: 20px;
    border-radius: 20px;
    height: 600px;
    width: 50vw;
}
.title1 {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
}
.subTitle1 {
    color: #ffffff;
}
.fields1 {
    border: #f3ec1a solid 1px;
    color: #ffffff !important;
    margin-bottom: 20px;
}
.donationButton1 {
    background-color: #f3ec1a !important;
    color: #000000 !important;
    height: 50px;
    width: 220px;
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .donationButton2 {
    background-color: #f3ec1a !important;
    color: #000000 !important;
    height: 50px;
    width: 220px;
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  @media (max-width: 768px) {
    .contactCard1 {
        margin: 20px;
        text-align: left;
        background-color: #633e98 !important;
        padding: 20px;
        border-radius: 20px;
        height: 100%;
        width: 100vw;
    }
  }