.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 95%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Container {
  width: 100%;
  text-align: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 220px;
  width: 100vw !important;
  background-color: #633e988a;
  z-index: 1;
  margin-bottom: 140px;
  border-radius: 0px;
  justify-content: center;
}
.fullPart {
  color: #ffffff;
  width: 100%;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100vw !important;
  background-color: #633e98b4;
  z-index: 1;
  margin-bottom: 140px;
  border-radius: 0px;
  justify-content: center;
}

.heading2 {
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 0px;
  color: white;
}
.heading3 {
  align-items: center;
  align-items: center;
  margin-top: 0px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: #f3ec1a;
}

.about {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 220px;
  width: 100vw !important;
  background-color: #633e98;
  z-index: 1;
  margin-bottom: -50px;
  padding-bottom: 20px;
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
}
.Containers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  height: 100%;
  margin-left: 50px;
}
.Containers p:first-child {
  font-size: 25px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}
.Containers p:nth-child(2) {
  font-size: 60px;
  font-weight: 800;
  color: #f3ec1a;
  margin: 0;
}
.bContainers {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  height: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  padding-right: 70px;
}

.story {
  height: 500px;
  width: 100vw !important;
  display: flex;
  justify-content: space-between;
}
.storyContainers {
  justify-content: start;
  align-items: center;
  text-align: left;
  height: 100%;
}
.storyParagraph {
  color: #000000;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  padding-right: 70px;
  padding-left: 100px;
}
.storyParagraph1 {
  color: #000000;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  padding-right: 100px;
  padding-left: 70px;
  padding-top: 120px;
  text-align: left;
}
.storyParagraph2 {
  color: #000000;
  font-weight: 300;
  font-size: 20px;
  line-height: 40px;
  padding-right: 70px;
  padding-left: 100px;
}
.topCard {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  margin-top: 50px;
  height: 100px;
  background-color: #633e98;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomCard {
  height: 200px;
  background-color: #f3ec1a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topCardParagraph {
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  padding-right: 50px;
}
.bottomCardParagraph {
  font-size: 25px;
  font-weight: 500 !important;
  color: #000000;
  text-align: left;
  padding: 20px;
}
.valuesTitle {
  font-size: 50px;
  font-weight: 600;
}
.valueCard {
  display: flex;
  align-items: center;
}
.valueHead {
  text-align: left;
  align-items: center;
  display: flex;
  padding-left: 20px;
  .p {
    margin-left: 20px !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    color: #ffffff !important;
  }
}
.valueParagraph {
  font-size: 18px !important;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
}
.storyParagraph3 {
  color: #000000;
  font-weight: 300;
  font-size: 20px;
  line-height: 40px;
  padding-right: 70px;
  padding-left: 100px;
  text-align: left;
  padding-top: 70px;
}
.ImageContainers {
  height: 100%;
  margin-left: 50px;
}
.storyImage {
  width: 45vw;
  border-radius: 20px;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}
.storyImage1 {
  /* width: 45vw;
  height: 45vh; */
  border-radius: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.storyHeader1 {
  padding-top: 50px;
  padding-left: 100px;
  font-size: 20px;
  font-weight: 700;
  color: #646464;
  margin: 0;
}
.storyHeader2 {
  padding-left: 100px;
  font-size: 50px;
  font-weight: 600;
  color: #633e98;
  margin: 0;
}
.parentContainer {
  /* margin-top: 100px; */
  margin-bottom: 100px;
}
.StoryContainer {
  /* margin-top: 100px; */
  margin-bottom: 700px;
}
.storyButton {
  margin-left: 100px !important;
  background-color: #633e98 !important;
  color: #ffffff !important;
  height: 50px;
  width: 220px;
  font-size: 20px !important;
  font-weight: 700 !important;
}
.donationButton {
  margin-left: 100px !important;
  background-color: #f3ec1a !important;
  color: #000000 !important;
  height: 50px;
  width: 220px;
  font-size: 20px !important;
  font-weight: 700 !important;
}
.firstPart {
  margin-left: 120px;
  border-bottom: solid 1px #000000;
  border-left: solid 1px #000000;
}
.connectingLine {
  border-left: 1px solid #000000;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  height: 70%;
  position: absolute;
  /* left: 10%; */
  width: 50px;
  margin-left: 50px;
  margin-top: 300px;
}

.secondPart {
  margin-top: 100px;
  margin-left: 120px;
  border-bottom: solid 1px #000000;
  border-left: solid 1px #000000;
}
@media (max-width: 768px) {
  .StoryContainer {
    /* margin-top: 100px; */
    margin-bottom: 50px;
  }
  .storyImage1 {
    width: 90% !important;
    border-radius: 40px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
  }
  .storyParagraph3 {
    color: #000000;
    font-weight: 300 !important;
    font-size: 20px;
    line-height: 40px;
    text-align: left !important;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: left !important;
    /* width: 100% !important; */
  }
  .bottomCard {
    height: 300px;
    background-color: #f3ec1a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottomCardParagraph {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #000000;
    text-align: left;
    padding: 20px;
  }
  .story {
    height: 100%;
    width: 100vw !important;
    z-index: 1;
    display: flex;
    justify-content: space-between;
  }
  .firstPart {
    margin: 0px;
  }
  .storyParagraph2 {
    color: #000000;
    font-weight: 300;
    font-size: 20px;
    line-height: 40px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .storyContainers {
    justify-content: start;
    align-items: center;
    text-align: center;
    width: 100vw !important;
  }
  .connectingLine {
    display: none;
  }
  .storyParagraph {
    color: #000000;
    font-weight: 500;
    font-size: 15px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .ImageContainers {
    display: none;
  }
  .secondPart {
    margin: 0;
  }
  .storyImage {
    width: 80vw;
    border-radius: 5px;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
  }
  .storyHeader1 {
    padding-left: 0px;
    font-size: 20px;
    font-weight: 700;
    color: #646464;
    margin: 0;
  }
  .storyHeader2 {
    padding-left: 0px;
    font-size: 50px;
    font-weight: 600;
    color: #633e98;
    margin: 0;
  }
  .storyParagraph1 {
    padding: 20px 20px;
    color: #000000;
    font-weight: 500;
    font-size: 15px;
    justify-content: center;
  }
  .donationButton {
    margin: 0 !important;
    background-color: #f3ec1a !important;
    color: #000000 !important;
    height: 40px !important;
    width: 220px;
    font-size: 20px !important;
    font-weight: 700 !important;
  }
}
